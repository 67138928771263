import posthog from 'posthog-js';

export const initPosthog = () => {
  if (typeof window !== 'undefined') {
    posthog.init(process.env.REACT_APP_POSTHOG_API_KEY, {
      api_host: process.env.REACT_APP_POSTHOG_INSTANCE_URL,
    });
  }
  return posthog;
};
