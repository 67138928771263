import { lazy } from 'react';
export const OnboardingPage = lazy(() => import('./Onboard'));
export const LoginPage = lazy(() => import('./Login'));
export const ForgotPasswordPage = lazy(() => import('./ForgotPassword'));
export const ConfirmPage = lazy(() => import('./Confirm'));
export const NoSourcesPage = lazy(() => import('./NoSources'));
export const NoSourceClientPage = lazy(() => import('./NoSourceClient'));
export const SummaryPage = lazy(() => import('./Summary'));
export const DiscoverPage = lazy(() => import('./Discover'));
export const VerifyEmail = lazy(() => import('./VerifyEmail'));
export const DashboardPage = lazy(() => import('./Dashboard'));
export const QueryPage = lazy(() => import('./Query'));
export const SettingsPage = lazy(() => import('./Settings'));
export const DashboardContent = lazy(() => import('./Dashboard/DashboardContent').then(({ DashboardContent }) => ({ default: DashboardContent })));
export const SetDefaultHomePage = lazy(() => import('./Settings/SetDefaultHomePage'));
export const Team = lazy(() => import('./Settings/Team'));
export const Digest = lazy(() => import('./Settings/Digest'));
export const IntegrationPage = lazy(() => import('./Settings/Integrations'));
export const IntegrationSucceeded = lazy(() =>
  import('./Settings/Integrations/IntegrationPage/IntegrationStatus/IntegrationSucceeded').then(({ IntegrationSucceeded }) => ({
    default: IntegrationSucceeded,
  }))
);
export const ThemesPage = lazy(() => import('./Settings/Theme'));
export const EmailPreview = lazy(() => import('./EmailPreview'));
export const UnsubscribeDigest = lazy(() => import('./Dashboard/DashboardOptions/CustomDigest/Unsubscribe'));
export const HomePage = lazy(() => import('./Home'));
export const AlertsPage = lazy(() => import('./Alerts'));
export const YourAlerts = lazy(() => import('./Alerts/YourAlerts'));
export const AlertSetup = lazy(() => import('./Alerts/AlertSetup'));
export const InvestigateAlert = lazy(() => import('./Alerts/InvestigateAlert'));
export const ReportsPage = lazy(() => import('./Reports'));
export const ReportContent = lazy(() => import('./Reports/ReportContent'));

export * from './AuthVerifier';
