import { FC, useCallback, useEffect, useState } from 'react';
import { Box, Text, Anchor } from 'grommet';
import moment from 'moment';
import { Expand, Link } from 'grommet-icons';
import Tags from './Tags';
import { TRACK_EVENT, trackFilterEvent } from 'src/core/trackEvent';
import { objectOperations } from 'Utils';
import {
  INTEGRATION_DATA_SOURCE_PROPERTY_LINK,
  INTEGRATION_DATA_SOURCE_PROPERTIES,
  INTEGRATION_DATA_SOURCE_TYPES,
} from 'Enums/integrationDataSourceTicketRedirectTypes';
import { removeUnderscore, addSpaceAtCamelCasePosition } from 'Utils/stringReplaceOperations';
import {
  CONVERSATION_SOURCE_VIEW_TYPES,
  SUPPORT_SOURCE_CONVERSATION_TAB_LIST,
  CSAT_SOURCE_CONVERSATION_TAB_LIST,
} from 'Enums/conversationResponseViewTypes';
import { ADMIN_INDEX_SUPPORT_TYPES } from 'Enums/SourceModelTypes';
import { updateSelectedConvViewTabId } from 'Slices/timeseries';
import { FiExternalLink } from 'react-icons/fi';

import { updateShowConversationMessage } from 'Slices/timeseries';
import { useAppSelector, useAppDispatch } from 'src/core/store';
import { Switch } from 'src/components/Switch';
import * as HoverCard from '@radix-ui/react-hover-card';
import format from 'string-template';
import { ConversationAudio } from './ConversationAudio';
import { useMeasure } from 'react-use';
import { ConversationData } from '../../types';
import { useLDFlags } from 'Hooks/useLaunchDakly';
import { cn } from 'Utils/TailwindUtils';
import { useInboxContext } from 'src/ui/containers/QuickInsight/Conversations';
import { ConversationImages } from './ConversationImages';

const ExpandMetadataModal: FC<{ metadata: Record<string, any> }> = ({ metadata }) => {
  const { excludedMetadataFields } = useLDFlags();
  const getMetaData = useCallback(([key, value]) => {
    if (!!key && (!!value || typeof value === 'boolean')) {
      const flattenMetadata = objectOperations.flattenObjectWithParent(key, value);
      return Object.keys(flattenMetadata).map((metadataKey) => {
        const keyText = removeUnderscore(addSpaceAtCamelCasePosition(metadataKey));
        const valueData = flattenMetadata[metadataKey];
        return (
          <div key={metadataKey} className="grid grid-cols-2 gap-2 border-b-[0.5px] border-gray-200 py-1 text-sm last:border-b-0">
            <span className="font-semibold capitalize text-gray-700">{keyText.toString()}</span>
            <span className="text-gray-600">{valueData.toString()}</span>
          </div>
        );
      });
    }
    return null;
  }, []);
  const { custom_fields, ...rest } = metadata;
  metadata = { ...rest, ...custom_fields };
  return (
    <Box gap="xsmall" pad="small" justify="center" round="xsmall" elevation="small" background="white" width={{ max: 'large' }}>
      <Box
        gap="small"
        style={{ display: 'block' }}
        overflow={{
          vertical: 'auto',
          horizontal: 'hidden',
        }}
      >
        <Box flex="grow">
          {Object.entries(metadata)
            .filter(([key]) => key !== 'recording' && !excludedMetadataFields.includes(key))
            .sort(([keyA], [keyB]) => keyA.localeCompare(keyB))
            .map(getMetaData)}
        </Box>
      </Box>
    </Box>
  );
};

const HeaderItem = ({
  name,
  value,
  className = '',
  metadata = { dataSource: '', subdomain: { zendesk: '', dixa: '' }, facebook_comment_link: '' },
  isLink = false,
}) => {
  const flags = useLDFlags();
  if (
    (name === INTEGRATION_DATA_SOURCE_PROPERTIES.ID &&
      ((metadata.subdomain &&
        (metadata.dataSource === INTEGRATION_DATA_SOURCE_TYPES.ZENDESK || metadata.dataSource === INTEGRATION_DATA_SOURCE_TYPES.DIXA)) ||
        metadata.dataSource === INTEGRATION_DATA_SOURCE_TYPES.TRUSTPILOT)) ||
    (metadata.dataSource === INTEGRATION_DATA_SOURCE_TYPES.FACEBOOK && metadata.facebook_comment_link !== '') ||
    (flags.ticketIdLink && name === 'ID')
  ) {
    const linkIconLabel = () => (
      <>
        {value} <Link size={'16px'} />
      </>
    );
    if (metadata.dataSource === INTEGRATION_DATA_SOURCE_TYPES.FACEBOOK && metadata.facebook_comment_link !== '') {
      return (
        <Text size="small">
          {name}
          {': '}
          <Anchor href={metadata.facebook_comment_link} target={'_blank'} label={linkIconLabel()} />
        </Text>
      );
    }
    const linkTemplate = flags.ticketIdLink
      ? `${flags.ticketIdLink}${value}`
      : INTEGRATION_DATA_SOURCE_PROPERTY_LINK[metadata.dataSource][name]?.LINK;
    const subdomain = metadata.subdomain ? metadata.subdomain[metadata.dataSource] : null;
    const link = subdomain ? format(linkTemplate, subdomain, value) : format(linkTemplate, value);
    return (
      <Text size="small">
        {name}
        {': '}
        <Anchor href={link} target={'_blank'} label={linkIconLabel()} />
      </Text>
    );
  }
  return (
    <div className={cn('flex gap-0.5 text-sm', className)}>
      <p>{name}:</p>
      {isLink ? (
        <a href={value} target="_blank" rel="noreferrer" className="w-72 truncate text-indigo-600 hover:underline">
          {value}
        </a>
      ) : (
        <p className="truncate">{value}</p>
      )}
    </div>
  );
};

interface HeaderProps extends Partial<ConversationData> {
  id: string;
}

const Header: FC<HeaderProps> = ({ id, date, ...rest }) => {
  const { selectedSourceClientData } = useAppSelector((state) => state.filter);
  const { conversationAudioEnabled, showMetadataInHeader, redditPostAndImages, excludedMetadataFields, showAgentQaCard } = useLDFlags();
  const domain = selectedSourceClientData?.subdomains;
  const dataSource = rest?.metadata?.dataSource;
  const facebook_comment_link = rest.metadata?.metadata?.fb_comment_link_na;
  const [ref, { width }] = useMeasure<HTMLDivElement>();
  const imagesUrl = rest.metadata.metadata['image_paths'] as string[];
  const showConversationAudio =
    typeof rest.metadata?.metadata?.recording === 'object' && rest.metadata?.metadata?.recording['isStored'] === true && conversationAudioEnabled;
  return (
    <div className="space-y-3" ref={ref}>
      <div className="flex justify-between gap-3">
        <div className={cn('flex flex-col', showConversationAudio ? 'max-w-[calc(100%-9.5rem)]' : 'max-w-[calc(100%-2rem)]')}>
          {showMetadataInHeader === 'Store Name' ? (
            <HeaderItem name="Store Name" value={rest.metadata?.metadata?.['Store Name']} />
          ) : (
            <HeaderItem
              name="ID"
              value={id}
              metadata={{ dataSource: dataSource, subdomain: domain, facebook_comment_link: facebook_comment_link as string }}
            />
          )}
          <HeaderItem name="Date" value={moment.utc(date).local().format('DD/MM/YYYY hh:mm A')} />
          {rest.metadata?.channel && <HeaderItem name="Channel" value={rest.metadata.channel} />}
          {rest.metadata?.rating && <HeaderItem name="Satisfaction Rating" value={rest.metadata.rating} />}
          {showMetadataInHeader === 'platform' && rest.metadata?.metadata?.platform && (
            <HeaderItem name="Platform" value={rest.metadata?.metadata?.platform} />
          )}
          {showMetadataInHeader === 'predicted csat' && rest.metadata?.metadata?.['predicted csat'] && (
            <HeaderItem name="Predicted CSAT" value={rest.metadata?.metadata?.['predicted csat']} />
          )}
          {redditPostAndImages && rest.metadata?.metadata?.url && <HeaderItem name="Reddit Post" value={rest.metadata.metadata?.url} isLink />}
          {rest.qaPreformanceScore && !excludedMetadataFields.includes('qaPreformanceScore') && !showAgentQaCard && (
            <HeaderItem
              className="font-semibold text-indigo-600"
              name="Agent QA Score"
              value={Number.parseFloat(rest.qaPreformanceScore.split('%')[0]).toFixed() + '%'}
            />
          )}
          {rest.metadata?.metadata?.response_url && (
            <Anchor target="_blank" size="small" href={rest.metadata?.metadata?.response_url as string} weight="500">
              <Box direction="row" gap="xsmall" align="center">
                Response Url <FiExternalLink size="0.9rem" strokeWidth="2.5" />
              </Box>
            </Anchor>
          )}
        </div>
        {showConversationAudio && (
          <ConversationAudio containerWidth={width} compressedFilePath={rest.metadata.metadata.recording['compressedFilePath']} key={id} />
        )}
      </div>
      {redditPostAndImages && imagesUrl?.length > 0 && <ConversationImages imagesUrl={imagesUrl} />}
      {rest.metadata?.metadata && (
        <HoverCard.Root onOpenChange={(open) => open && trackFilterEvent(TRACK_EVENT.EXPAND_METADATA, { id })}>
          <HoverCard.Trigger className="flex w-fit items-center gap-2" role="button">
            <Expand size="small" />
            <Text size="small">Expand</Text>
          </HoverCard.Trigger>
          <HoverCard.Content
            side="bottom"
            align="start"
            sideOffset={5}
            className="z-50 max-h-[27rem] max-w-[33rem] overflow-y-scroll rounded-md border border-gray-1100 shadow-[0_4.2px_10.5px_rgba(0,0,0,0.12)]"
          >
            <ExpandMetadataModal metadata={rest.metadata.metadata} />
          </HoverCard.Content>
        </HoverCard.Root>
      )}
    </div>
  );
};

const SimplePlainTab = ({ activeTab, tabs, onChange }) => {
  const [tabList, setTabList] = useState([]);
  useEffect(() => {
    if (Array.isArray(tabs)) setTabList(tabs);
    else if (objectOperations.isObjectType(tabs)) setTabList(Object.keys(tabs).map((key) => CONVERSATION_SOURCE_VIEW_TYPES[key]));
  }, []);
  return (
    <Box flex="grow" align="center" overflow="hidden" direction="row" width="100%" round="4px">
      {tabList?.map((tabName, index) => (
        <Box
          key={index}
          width={'auto'}
          align={'center'}
          overflow={'hidden'}
          justify={'center'}
          style={{ flexBasis: '100%' }}
          pad={'6px'}
          background={activeTab === tabName ? '#FFF5ED' : '#FBFBFB'}
          onClick={(event) => {
            event.preventDefault();
            if (tabName !== activeTab) onChange(tabName);
          }}
        >
          <Text size={'small'} className="capitalize">
            {tabName}
          </Text>
        </Box>
      ))}
    </Box>
  );
};

interface InboxViewHeaderProps {
  closeConversationInfoView: () => void;
  allTags: string[];
  type: 'survey' | 'support';
  ticket?: ConversationData;
  CSAT?: ConversationData;
}

const InboxViewHeader: FC<InboxViewHeaderProps> = ({ type, closeConversationInfoView, allTags, ...restProps }) => {
  const dispatch = useAppDispatch();
  const { selectedSourceClientData } = useInboxContext();
  const selectedConvViewTabId = useAppSelector((state) => state.timeseries.selectedConvViewTabId);
  const isConvViewTabDisplayed = useAppSelector((state) => state.timeseries.isConvViewTabDisplayed);

  let restPropsValue: ConversationData;
  if (isConvViewTabDisplayed && restProps?.[selectedConvViewTabId]) restPropsValue = restProps?.[selectedConvViewTabId];
  else restPropsValue = restProps?.[CONVERSATION_SOURCE_VIEW_TYPES.TICKET];

  const id = restPropsValue?.id;
  const tags = restPropsValue?.tags;
  const rest = { ...(restPropsValue ?? {}) };

  const showConvTranslatedMessage = useAppSelector((state) => state.timeseries?.showConvTranslatedMessage);
  const isConvMultilingual = rest?.metadata?.metadata?.lang !== 'en';
  const updateViewTabId = (activeTabValue) => dispatch(updateSelectedConvViewTabId(activeTabValue));

  const getTabList = () => {
    if (ADMIN_INDEX_SUPPORT_TYPES.includes(selectedSourceClientData?.es_alias?.type)) return SUPPORT_SOURCE_CONVERSATION_TAB_LIST;
    return CSAT_SOURCE_CONVERSATION_TAB_LIST;
  };
  return (
    <div className="px-6 py-3">
      <div className="space-y-3">
        {isConvViewTabDisplayed && (
          <Box width={'100%'}>
            <SimplePlainTab activeTab={selectedConvViewTabId} tabs={getTabList()} onChange={(value) => updateViewTabId(value)} />
          </Box>
        )}
        <Header id={id} {...rest} />
        <Tags id={id} type={type} tags={tags} allTags={allTags} />
        {isConvMultilingual && (
          <Box direction={'row'} gap={'small'} align={'center'} justify={'end'} width={'100%'}>
            <Text size="small" color={'#444444'}>
              {showConvTranslatedMessage ? 'Translated' : 'Original'}
            </Text>
            <Switch
              checked={showConvTranslatedMessage}
              onCheckedChange={(value) => dispatch(updateShowConversationMessage({ showConvTranslatedMessage: value }))}
              size="large"
            />
          </Box>
        )}
      </div>
    </div>
  );
};

export default InboxViewHeader;
