import React, { useCallback, useEffect, useMemo } from 'react';
import { FiDatabase } from 'react-icons/fi';
import { useNavigate } from 'react-router-dom';
import { resetAnalyticsReduxState } from 'Slices/analytics';
import { getSourceOptions, resetFilterReduxState, updateDateRangeBounds, updateFilters, updateDrillDownTopic } from 'Slices/filter';
import { resetFilterBookmarksReduxState } from 'Slices/filterbookmarks';
import { resetSummaryReduxState } from 'Slices/summary';
import { resetTimeseriesReduxState } from 'Slices/timeseries';
import { Dropdown } from 'src/components/Dropdown';
import { useAppDispatch, useAppSelector } from 'src/core/store';
import { TRACK_EVENT, trackEvent } from 'src/core/trackEvent';
import { ISourceOptions } from 'src/types/Source';
import { SourceLabel } from './SourceLabel';
import { useLDFlags, useLaunchDakly } from 'Hooks/useLaunchDakly';

export const SourcesDropdown: React.FC = () => {
  const dispatch = useAppDispatch();
  const { filters } = useAppSelector((state) => state.filter);
  const user = useAppSelector((state) => state.auth.user);
  const navigate = useNavigate();
  const ldClient = useLaunchDakly();
  const { defaultEsAlias } = useLDFlags();
  const source = filters?.source;
  const sourceOptions = useMemo(
    () =>
      getSourceOptions(user)
        .map((option) => ({ label: option.displayName, value: option }))
        .sort(({ value }) => (value.name === defaultEsAlias ? -1 : 0)),
    [user, defaultEsAlias]
  );
  const selectedSourceOption = useMemo(() => sourceOptions.find((option) => option.value.name === source), [source, sourceOptions]);
  const onFilter = useCallback((filters: ISourceOptions) => dispatch(updateFilters(filters)), [dispatch]);
  const onClientsFilter = useCallback(
    async (filter: ISourceOptions) => {
      if (filter.name === source) return;
      dispatch(resetSummaryReduxState());
      dispatch(resetAnalyticsReduxState());
      dispatch(resetFilterReduxState({}));
      dispatch(resetFilterBookmarksReduxState());
      dispatch(resetTimeseriesReduxState());
      dispatch(updateDrillDownTopic(null));
      trackEvent(TRACK_EVENT.FILTER_SOURCE, {
        Source: filter.name,
        Metric_Types: filter?.types,
      });
      const { defaultDateRangeInterval, defaultDateField } = await ldClient.updateLDContext({ custom: { source: filter.name } });
      navigate(window.location.pathname);
      onFilter(filter);
      dispatch(updateDateRangeBounds({ source: filter.name, defaultDateRangeInterval, defaultDateField }));
    },
    [dispatch, onFilter, source]
  );
  useEffect(() => {
    if (filters?.source)
      ldClient
        .updateLDContext({ custom: { source: filters.source } })
        .then(({ defaultDateRangeInterval, defaultDateField }) =>
          dispatch(updateDateRangeBounds({ source: filters.source, defaultDateRangeInterval, defaultDateField }))
        );
  }, []);
  return (
    <div className="w-[stretch] bg-white">
      <div className="flex h-[55px] items-center justify-between border-b px-7">
        <Dropdown
          selected={selectedSourceOption}
          options={sourceOptions}
          onChange={onClientsFilter}
          icon={<FiDatabase size={20} className="text-grey-500" />}
          searchPlaceholder="Search source"
          enableSearch={true}
          label={SourceLabel}
          data-testid="source-dropdown"
        />
      </div>
    </div>
  );
};
