import { FiBookOpen, FiHome, FiLayers } from 'react-icons/fi';
import { HiOutlineSquares2X2 } from 'react-icons/hi2';
import { useAppSelector } from 'src/core/store';
import { SidebarNavLink } from './SidebarNavLink';
import { UserProfile } from './UserProfile';
import { IntegrationLink } from './IntegrationLink';
import { anamolySourcesSelector, isUserAdmin, sourcesAvailableSelector } from 'Slices/auth';
import { UserInviteButton } from './UserInviteButton';
import { SidebarLogo } from './SidebarLogo';
import { AlertOutline } from 'Static/images';
import { SidebarTooltip } from './SidebarTooltip';
import { useLDFlags } from 'Hooks/useLaunchDakly';
import { TbReport } from 'react-icons/tb';

export const NavigationSidebar = () => {
  const clientsLength = useAppSelector((state) => state.auth.user?.clients?.length);
  const isAdmin = useAppSelector(isUserAdmin);
  const isInviteEnabled = isAdmin && clientsLength > 0;
  const sourceAvailable = useAppSelector(sourcesAvailableSelector);
  const anomalySources = useAppSelector(anamolySourcesSelector);
  const isAnomalyEnabled = useLDFlags().anomalyStatus;

  return (
    <div className="flex h-screen w-16 flex-col border-r bg-white text-grey-600">
      <div className="flex h-[55px] items-center justify-center border-b">
        <SidebarLogo />
      </div>
      <div className="flex grow flex-col justify-between gap-4 px-2 pb-6 pt-3">
        <div className="flex flex-col items-center gap-4">
          <SidebarNavLink to="/" icon={FiHome} title="Home" end />
          {sourceAvailable && (
            <>
              <SidebarNavLink
                to="/discover"
                icon={FiBookOpen}
                title="Discover"
                description="Deep dive into tags & tickets"
                className="hover:bg-white"
                activeClassName="hover:!bg-indigo-600"
              />
              <SidebarNavLink
                to="/dashboard"
                icon={HiOutlineSquares2X2}
                title="Your Dashboards"
                description="Customisable dashboards & reports"
                data-tour-element="dashboard-navigation-sidebar"
              />
              {anomalySources.length > 0 && isAnomalyEnabled && (
                <SidebarNavLink
                  to="/alerts"
                  icon={AlertOutline}
                  title="Anomalies"
                  description="See what's trending"
                  data-tour-element="dashboard-navigation-sidebar"
                  data-anomaly-element="alerts-sidebar"
                />
              )}
            </>
          )}
        </div>
        <div className="flex flex-col items-center gap-4">
          {clientsLength > 0 && <IntegrationLink />}
          {isInviteEnabled && <UserInviteButton />}
          {clientsLength > 0 && (
            <SidebarNavLink
              to="/theme"
              icon={FiLayers}
              title="Theme Management"
              description="Customise and manage tag groups"
              data-tour-element="dashboard-navigation-sidebar"
            />
          )}
          <UserProfile />
        </div>
      </div>
      <SidebarTooltip />
    </div>
  );
};
