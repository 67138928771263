import React, { useState, useEffect, useRef } from 'react';
import { FiChevronLeft, FiChevronRight, FiImage, FiX } from 'react-icons/fi';
import { useKey } from 'react-use';
import * as Dialog from 'src/components/Dialog/NestedDialog';
import { cn } from 'Utils/TailwindUtils';
import { LuImagePlus } from 'react-icons/lu';

export const ConversationImages = ({ imagesUrl }: { imagesUrl: string[] }) => {
  const [showDialog, setShowDialog] = useState(false);
  const [activeImageIndex, setActiveImageIndex] = useState(0);
  const dialogRef = useRef(null);

  const handleNext = () => {
    setActiveImageIndex((prevIndex) => (prevIndex + 1) % imagesUrl.length);
  };

  const handlePrev = () => {
    setActiveImageIndex((prevIndex) => (prevIndex - 1 + imagesUrl.length) % imagesUrl.length);
  };

  useKey('ArrowLeft', () => handlePrev(), {}, []);
  useKey('ArrowRight', () => handleNext(), {}, []);
  useKey('Escape', () => setShowDialog(false), {}, []);

  useEffect(() => {
    const handleKeydown = (e: KeyboardEvent) => {
      if (showDialog) {
        if (e.key === 'ArrowLeft') {
          e.preventDefault();
          e.stopPropagation();
          handlePrev();
        } else if (e.key === 'ArrowRight') {
          e.preventDefault();
          e.stopPropagation();
          handleNext();
        } else if (e.key === 'Escape') {
          e.preventDefault();
          e.stopPropagation();
          setShowDialog(false);
        }
      }
    };

    if (showDialog) document.addEventListener('keydown', handleKeydown);
    else document.removeEventListener('keydown', handleKeydown);
    return () => {
      document.removeEventListener('keydown', handleKeydown);
    };
  }, [showDialog]);

  return (
    <Dialog.Root open={showDialog} onOpenChange={setShowDialog}>
      <Dialog.Trigger asChild>
        <button className="!-mt-0 flex items-center gap-1 pt-0.5 text-sm text-gray-500">
          <LuImagePlus className="h-4 w-4" />
          <span>
            {imagesUrl.length} image{imagesUrl.length > 1 ? 's' : ''} attached
          </span>
        </button>
      </Dialog.Trigger>
      <Dialog.NestedDialogContent ref={dialogRef} isOpen={showDialog} className="h-[74%] w-[36%] rounded-lg p-8" addPortal>
        <span onClick={() => setShowDialog(false)} className="absolute right-4 top-4 rounded-sm opacity-70" role="button">
          <FiX className="h-4 w-4" />
        </span>
        <div className="relative flex h-full items-center justify-center">
          {imagesUrl.length > 1 && (
            <button onClick={handlePrev} className="absolute -left-2 z-10 p-1">
              <FiChevronLeft className="h-6 w-6 text-gray-800" />
            </button>
          )}
          <div className="overflow-y-auto p-2">
            <img
              src={imagesUrl[activeImageIndex]}
              alt={`Conversation image ${activeImageIndex + 1}`}
              className="h-[35rem] w-96 rounded-md object-contain shadow-lg"
            />
          </div>
          {imagesUrl.length > 1 && (
            <button onClick={handleNext} className="absolute -right-2 z-10 p-1">
              <FiChevronRight className="h-6 w-6 text-gray-800" />
            </button>
          )}
        </div>
        {imagesUrl.length > 1 && (
          <div className="absolute inset-x-0 bottom-4 flex justify-center gap-2">
            {imagesUrl.map((_, index) => (
              <button
                key={index}
                className={cn('h-2 w-2 rounded-full border p-0', index === activeImageIndex ? 'border-black bg-black' : 'bg-gray-200')}
                onClick={() => setActiveImageIndex(index)}
              >
                <span className="sr-only">Image {index + 1}</span>
              </button>
            ))}
          </div>
        )}
      </Dialog.NestedDialogContent>
    </Dialog.Root>
  );
};
